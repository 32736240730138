import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';

import logoInverted from 'assets/logo-inverted.png';
import logoIcon from 'assets/logo-icon-inverted.png';
import logoJDI from 'assets/logo-JDI.svg';

import iconMedium from 'assets/icon-medium.svg';
import iconTwitter from 'assets/icon-twitter.svg';
import iconFb from 'assets/icon-fb.svg';

import graphicHero from 'assets/graphics/hero-graphics.png';
import graphicMacbook from 'assets/graphics/macbook-pro-tablet.png';
import graphicCustomizable from 'assets/graphics/icon-cutomizable.png';
import graphicDomain from 'assets/graphics/icon-domain-support.png';
import graphicCoding from 'assets/graphics/icon-no-coding.png';

import clientApp from 'assets/client-logos/app-annie.png';
import clientAsics from 'assets/client-logos/asics.png';
import clientCerego from 'assets/client-logos/cerego.png';
import clientChaotic from 'assets/client-logos/chaotic-moon.png';
import clientConversable from 'assets/client-logos/conversable.png';
import clientEverlywell from 'assets/client-logos/everlywell.png';
import clientKernel from 'assets/client-logos/kernel.png';
import clientLoom from 'assets/client-logos/loom.png';
import clientPlacemeter from 'assets/client-logos/placemeter.png';
import clientRuntastic from 'assets/client-logos/runtastic.png';
import clientBonusly from 'assets/client-logos/bonusly.png';
import clientTravefy from 'assets/client-logos/travefy.png';
import clientViv from 'assets/client-logos/viv.png';
import clientArticle from 'assets/client-logos/article.svg';

import backPricing from 'assets/back-pricing.svg';

import pressMashable from 'assets/press-logos/mashable-logo-white.png';
import pressPR from 'assets/press-logos/PR-week.png';
import pressReadwrite from 'assets/press-logos/readwrite.png';
import pressTechcrunch from 'assets/press-logos/techcrunch.png';

const HomepageWrapper = styled.div`
  p {
    font-weight: 300;
  }
`;

const TopNav = styled(Menu)`
  &.ui.menu {
    background: transparent;
    font-size: '14px';
  }

  &.ui.menu.fixed {
    background: inherit;
  }

  &.ui.menu .item {
    border: 0 !important;
    margin-left: 0 !important;
    color: #fff;

    &::before {
      background: none;
    }
  }

  &.ui.menu a.item:hover {
    background: transparent;
  }

  &.ui.menu .item a:hover {
    color: rgba(255, 255, 255, 0.44);
  }

  &.ui.menu .item a:focus {
    color: #fe5141;
  }

  &.ui.menu .logo {
    padding-left: 0;
  }
`;

const SidebarPush = styled(Sidebar.Pushable)`
  .ui.menu.sidebar-menu {
    background: #043d46;
    text-align: center;
    padding: 0 0 3em 0;
  }

  .ui.inverted.menu .item {
    border: 0;
    padding: 12px;

    &::before {
      background: transparent;
    }

    &.close-button {
      text-align: right;
      padding: 24px;
      font-size: 24px;

      .icon {
        color: #fe5141;
      }
    }
  }
`;

const Hero = styled(Container)`
  h1.ui.header {
    font-weight: 600;
    margin-bottom: 44px;
    margin-top: 1.5em;

    @media (max-width: 767px) {
      font-size: 38px;
    }
  }

  .hero-image-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 4em 0 2em 0;
  }

  @media (max-width: 991px) {
    .break {
      display: none;
    }
  }

  .buttons-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .button:first-child {
      margin-right: 37px;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;

      .button:first-child {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }
`;

const HeroSegment = styled(Segment)`
  &.ui.segment {
    padding: 4em 0 1em 0;
    background: #34a89d;

    @media (max-width: 991px) {
      padding: 1em 0 6em 0;
    }

    @media (max-width: 767px) {
      padding: 0.25em 0 4em 0;
    }
  }
`;

const ClientsSegment = styled(Segment)`
  &.ui.segment {
    padding: 40px 0;
    border: 0;

    @media (max-width: 991px) {
      padding: 120px 0 100px;
    }

    @media (max-width: 767px) {
      padding: 140px 0;
    }
  }
`;

const ClientImages = styled(Grid.Column)`
  .row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 30px 0;

    @media (max-width: 991px) {
      &:first-child {
        margin-top: 25px;
      }

      padding: 25px 0;
    }

    @media (max-width: 767px) {
      &:first-child {
        margin-top: 65px;
      }

      flex-direction: column;
      padding: 0;
    }
  }

  img {
    max-width: 110px;

    @media (max-width: 767px) {
      margin: 25px 0;
    }
  }
`;

const MacbookWrap = styled(Grid.Column)`
  img {
    width: 700px;
    margin-right: -130px;
    max-width: none !important;
    overflow: hidden;

    @media (max-width: 991px) {
      width: 100%;
      margin: 0;
    }

    @media (max-width: 767px) {
      width: 150%;
    }
  }

  @media (max-width: 991px) {
    padding-top: 100px;
  }

  @media (max-width: 767px) {
    padding-top: 60px;
  }
`;

const EverythingSegment = styled(Segment)`
  &.ui.segment {
    padding: 130px 0;
    border: 0;

    @media (max-width: 991px) {
      padding: 100px 0;
    }

    @media (max-width: 767px) {
      padding: 140px 0;

      .column p {
        padding-bottom: 40px;
      }
    }
  }
`;

const PricingSegment = styled(Segment)`
  &.ui.segment {
    padding: 0 0 130px 0;
    border: 0;
    background: #f4f5f6;
  }

  .column.title-column {
    margin-bottom: 85px !important;

    @media (max-width: 991px) {
      margin-bottom: 70px !important;
    }

    @media (max-width: 767px) {
      margin-bottom: 60px !important;
    }
  }

  .buttons-pricing {
    text-align: center;

    @media (max-width: 991px) {
      margin-top: 30px !important;
    }

    @media (max-width: 767px) {
      margin-top: 60px !important;
    }

    .button:first-child {
      margin-right: 15px;
    }

    @media (max-width: 991px) {
      text-align: left;
    }

    @media (max-width: 767px) {
      text-align: center;

      .button {
        margin: 0;
      }

      .button:first-child {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }

    @media (min-width: 768px) {
      .break {
        display: none;
      }
    }
  }
`;

const CutBackground = styled.div`
  background-image: url(${backPricing});
  background-size: cover;
  background-size: 100% 100%;
  width: 100%;
  height: 125px;

  @media (max-width: 991px) {
    height: 70px;
    margin-bottom: 10px;
  }

  @media (max-width: 767px) {
    height: 30px;
    margin-bottom: 110px;
  }
`;

const PressSegment = styled(Segment)`
  &.ui.segment {
    padding: 130px 0;
    border: 0;
    background: #fe5141;

    @media (max-width: 991px) {
      padding: 50px 0 100px 0;
    }

    @media (max-width: 767px) {
      padding: 75px 0 140px 0;
    }
  }
`;

const PressImages = styled(ClientImages)`
  &.column {
    margin-bottom: 80px !important;

    @media (max-width: 991px) {
      margin-bottom: 100px !important;
    }

    @media (max-width: 767px) {
      margin-bottom: 110px !important;
    }

    img {
      max-width: 200px;

      @media (max-width: 991px) {
        max-width: 150px;
      }

      @media (max-width: 767px) {
        max-width: 250px;
      }
    }

    a:hover {
      opacity: 0.7;
    }
  }
`;

const FooterSegment = styled(Segment)`
  &.ui.segment {
    padding: 90px 0;
    border: 0;
    background: #043d46;
    color: #fff;

    @media (max-width: 991px) {
      padding: 60px 0;
    }

    @media (max-width: 767px) {
      padding: 68px 0;
    }

    .footer-logo {
      width: 139px;

      @media (max-width: 767px) {
        display: block;
        margin: 0 auto 34px auto;
      }
    }

    .jdi-wrap {
      @media (max-width: 767px) {
        text-align: center !important;
        margin-top: 24px;
      }
    }
  }
`;

const FooterTop = styled(Grid.Row)`
  padding: 0;
  margin-bottom: 164px;

  @media (max-width: 991px) {
    margin-bottom: 45px;
  }

  @media (max-width: 767px) {
    margin-bottom: 28px;
  }
`;

const FooterLinks = styled.div`
  font-family: 'Poppins', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: 991px) {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    flex-direction: column;
    align-items: center;
  }

  a {
    color: #fff;
    margin-right: 24px;

    &:hover {
      color: #91abb0;
    }

    &:focus {
      color: #fe5141;
    }

    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
`;

const FooterCopyright = styled(Header)`
  color: #34a89d;
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;

  @media (max-width: 991px) {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    text-align: center;
    margin-top: 24px;
  }
`;

const FooterIcons = styled(Grid.Column)`
  a {
    margin-right: 16px;
  }

  a:last-child {
    margin-right: 0;
  }

  a:hover {
    opacity: 0.57;
  }

  @media (max-width: 767px) {
    text-align: center !important;
  }
`;

const StrongFont = styled.b`
  font-weight: 700;
`;

const HomepageHeading = ({ mobile }) => (
  <Hero>
    <Grid>
      <Grid.Column mobile={16} tablet={10} computer={12} verticalAlign="middle">
        <Header
          as="h1"
          content="The Hosted Press Page for Exceptional Companies."
          inverted
          style={{
            fontSize: mobile ? '' : '',
          }}
          className="hero-header"
        />
        <p style={{ fontSize: '1.2em', fontWeight: '300', marginBottom: '73px' }}>
          Totem is the easy way to get a great press page.
          <br className="break" />
          We help businesses make a great impression with the press.
        </p>
        <div className="buttons-wrap">
          <a
            className="ui button primary"
            style={{
              padding: '13px 45px',
            }}
            href="https://medium.com/building-the-best-press-page/the-next-big-thing-for-totem-34bb7606ef59#.2w9oh98bl">
            Our Story
          </a>
          <a
            className="ui button secondary"
            style={{
              padding: '13px 45px',
            }}
            href="https://www.totemapp.net/users/sign_up">
            Sign Up
          </a>
        </div>
      </Grid.Column>
      <Grid.Column tablet={6} computer={4} only="tablet computer">
        <div className="hero-image-wrapper">
          <Image src={graphicHero} />
        </div>
      </Grid.Column>
    </Grid>
  </Hero>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends React.Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });

  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive minWidth={992}>
        <Segment
          inverted
          style={{
            padding: '4em 0 2em 0',
            background: '#34A89D',
          }}
          vertical>
          <Visibility
            style={{
              background: '#34A89D',
            }}
            once={false} onBottomPassed={this.showFixedMenu} onBottomPassedReverse={this.hideFixedMenu}>
            <TopNav

              fixed={fixed ? 'top' : null} inverted>
              <Container>
                <Menu.Item as={Link} to="/" className="logo">
                  <img style={{ width: '275px' }} src={`${logoInverted}`} />
                </Menu.Item>
                <Menu.Menu position="right">
                  <Menu.Item>
                    <a href="https://www.totemapp.net/users/sign_in">Login</a>
                  </Menu.Item>
                  <Menu.Item>
                    <a href="https://medium.com/building-the-best-press-page">Blog</a>
                  </Menu.Item>
                  <Menu.Item>
                    <a href="mailto:concierge@totemapp.com">Support</a>
                  </Menu.Item>
                  <Menu.Item>
                    <a href="https://www.totemapp.net/users/sign_up">Sign Up</a>
                  </Menu.Item>
                </Menu.Menu>
              </Container>
            </TopNav>
          </Visibility>
          <HomepageHeading />
        </Segment>

        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends React.Component {
  state = {};

  handlePusherClick = () => {
    const { sidebarOpened } = this.state;

    if (sidebarOpened) this.setState({ sidebarOpened: false });
  };

  handleToggle = () => this.setState({ sidebarOpened: !this.state.sidebarOpened });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive maxWidth={991}>
        <SidebarPush>
          <Sidebar
            as={Menu}
            inverted
            vertical
            direction="top"
            animation="overlay"
            visible={sidebarOpened}
            className="sidebar-menu">
            <Menu.Item className="close-button">
              <a onClick={this.handlePusherClick}>
                <Icon name="close" />
              </a>
            </Menu.Item>
            <Menu.Item>
              <a href="https://www.totemapp.net/users/sign_in">Login</a>
            </Menu.Item>
            <Menu.Item>
              <a href="https://medium.com/building-the-best-press-page">Blog</a>
            </Menu.Item>
            <Menu.Item>
              <a href="mailto:concierge@totemapp.com">Support</a>
            </Menu.Item>
            <Menu.Item>
              <a href="https://www.totemapp.net/users/sign_up">Sign Up</a>
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened} onClick={this.handlePusherClick} style={{ minHeight: '100vh' }}>
            <HeroSegment inverted vertical>
              <Container>
                <TopNav inverted primary>
                  <Menu.Item as={Link} to="/" className="logo">
                    <img style={{ width: '230px' }} src={`${logoInverted}`} />
                  </Menu.Item>
                  <Menu.Menu position="right">
                    <Menu.Item onClick={this.handleToggle} style={{ paddingRight: '0' }}>
                      <Icon name="sidebar" style={{ color: '#043D46' }} />
                    </Menu.Item>
                  </Menu.Menu>
                </TopNav>
              </Container>
              <HomepageHeading mobile />
            </HeroSegment>

            {children}
          </Sidebar.Pusher>
        </SidebarPush>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  <HomepageWrapper>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </HomepageWrapper>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const HomepageLayout = () => (
  <ResponsiveContainer>
    <ClientsSegment vertical>
      <Grid container vertical>
        <Grid.Row>
          <Grid.Column computer={7} tablet={16} mobile={16} verticalAlign="middle">
            <Header as="h3" style={{ color: '#043D46', lineHeight: '48px' }}>
              Thousands of press pages. Infinitely better press coverage.
            </Header>
            <p>We’re proud of our customers. They’re proud of us too.</p>
          </Grid.Column>
          <ClientImages computer={8} tablet={16} mobile={16} floated="right">
            <Grid.Row>
              <img src={`${clientApp}`} />
              <img src={`${clientPlacemeter}`} style={{ maxWidth: '140px' }} />
              <img src={`${clientRuntastic}`} style={{ maxWidth: '140px' }} />
            </Grid.Row>
            <Grid.Row>
              <img src={`${clientAsics}`} />
              <img src={`${clientTravefy}`} />
              <img src={`${clientEverlywell}`} style={{ maxWidth: '140px' }} />
            </Grid.Row>
            <Grid.Row>
              <img src={`${clientChaotic}`} style={{ maxHeight: '78px' }} />
              <img src={`${clientKernel}`} />
              <img src={`${clientCerego}`} style={{ maxHeight: '66px' }} />
              <img src={`${clientViv}`} />
            </Grid.Row>
            <Grid.Row>
              <img src={`${clientBonusly}`} />
              <img src={`${clientConversable}`} style={{ maxWidth: '140px' }} />
              <img src={`${clientLoom}`} />
            </Grid.Row>
          </ClientImages>
        </Grid.Row>
      </Grid>
    </ClientsSegment>

    <Segment vertical style={{ padding: '130px 0', background: '#F4F5F6', border: '0' }}>
      <Grid container vertical>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header
              as="h3"
              style={{
                color: '#043D46',
                lineHeight: '48px',
                marginBottom: '24px',
              }}>
              Zero setup. Concierge management.
            </Header>
          </Grid.Column>
          <Grid.Column tablet={16} mobile={16} computer={9}>
            <Header
              as="h4"
              style={{
                textTransform: 'uppercase',
                color: '#FE5141',
                fontWeight: '400',
                marginTop: '84px',
              }}>
              Self-serve Editor
            </Header>
            <p>
              Signup and start building. Totem has everything you need to build a world class press page in minutes.
            </p>
            <Header
              as="h4"
              style={{
                textTransform: 'uppercase',
                color: '#FE5141',
                fontWeight: '400',
                marginTop: '40px',
              }}>
              Free Concierge Service
            </Header>
            <p>
              Our experts will build your press page for you for no additional charge. When you sign up, our concierge
              will contact you to offer assistance. If you ever have trouble, our concierge team is available to help.
            </p>
          </Grid.Column>
          <MacbookWrap computer={7} tablet={16} mobile={16} floated="right" verticalAlign="middle">
            <img src={`${graphicMacbook}`} />
          </MacbookWrap>
        </Grid.Row>
      </Grid>
    </Segment>

    <EverythingSegment vertical>
      <Grid container vertical stackable>
        <Grid.Row columns={3} textAlign="center">
          <Grid.Column width={16}>
            <Header
              as="h3"
              style={{
                color: '#043D46',
                lineHeight: '48px',
                marginBottom: '80px',
              }}>
              Everything you need. Nothing you don’t.
            </Header>
          </Grid.Column>
          <Grid.Column>
            <img src={`${graphicCustomizable}`} style={{ height: '110px', marginBottom: '24px' }} />
            <p>
              <StrongFont>Customizable page design</StrongFont> so your brand colors, fonts and imagery are highlighted
              on your press page.
            </p>
          </Grid.Column>
          <Grid.Column>
            <img src={`${graphicCoding}`} style={{ height: '110px', marginBottom: '24px' }} />
            <p>
              <StrongFont>Easy page updates</StrongFont>, no web developer required. Stop waiting in line for dev
              resources.
            </p>
          </Grid.Column>
          <Grid.Column>
            <img src={`${graphicDomain}`} style={{ height: '110px', marginBottom: '24px' }} />
            <p>
              <StrongFont>Custom domain support</StrongFont> so your press page isn’t siloed from your main site.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </EverythingSegment>

    <PricingSegment vertical>
      <CutBackground />
      <Grid container vertical>
        <Grid.Row centered>
          <Grid.Column computer={16} tablet={12} mobile={16} className="title-column">
            <Header as="h3" style={{ color: '#043D46', lineHeight: '48px' }}>
              Pricing
            </Header>
            <p>Totem is a paid product, with one simple pricing tier.</p>
          </Grid.Column>
          <Grid.Column computer={9} tablet={12} mobile={16} verticalAlign="middle" textAlign="left">
            <Header
              as="h2"
              style={{
                color: '#FE5141',
                fontWeight: '400',
                lineHeight: '56px',
              }}>
              No setup fee, $29/month, cancel anytime
            </Header>
          </Grid.Column>
          <Grid.Column computer={7} tablet={12} mobile={16} verticalAlign="middle" className="buttons-pricing">
            <a
              className="ui button primary"
              style={{
                padding: '13px 45px',
              }}
              href="https://medium.com/building-the-best-press-page/fine-print-b42f160cce0f#.b3q0zd21b">
              Fine Print
            </a>
            <br className="break" />
            <a
              className="ui button secondary"
              style={{
                padding: '13px 45px',
              }}
              href="https://www.totemapp.net/users/sign_up">
              I'm Sold
            </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </PricingSegment>

    <PressSegment vertical>
      <Grid container vertical textAlign="center">
        <Grid.Row>
          <PressImages width={16}>
            <Grid.Row>
              <a href="http://mashable.com/2012/02/07/totem/#uAAf95zEIkkW">
                <img src={`${pressMashable}`} />
              </a>
              <a href="http://techcrunch.com/2015/11/04/totem-redesign/">
                <img src={`${pressTechcrunch}`} />
              </a>
              <a href="http://www.prweek.com/article/1280164/jones-dilworth-launches-press-page-building-software">
                <img src={`${pressPR}`} style={{ maxWidth: '95px' }} />
              </a>
              <a href="http://readwrite.com/2012/02/07/how_to_get_my_attention">
                <img src={`${pressReadwrite}`} />
              </a>
            </Grid.Row>
          </PressImages>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            <Header
              as="h3"
              style={{
                fontWeight: '400',
                lineHeight: '56px',
                color: '#fff',
                marginBottom: '40px',
              }}>
              You should check out these awesome press pages, including Totem's:
            </Header>
          </Grid.Column>
          <Grid.Column width={16}>
            <ClientImages>
              <Grid.Row>
                <a href="https://everlywell.totemapp.net/">
                  <img src={`${clientEverlywell}`} style={{ maxWidth: '140px' }} />
                </a>
                <a href="https://article.totemapp.net/">
                  <img src={`${clientArticle}`} />
                </a>
                <a href="https://bonusly.totemapp.net/">
                  <img src={`${clientBonusly}`} />
                </a>
                <a href="https://travefy.totemapp.net/">
                  <img src={`${clientTravefy}`} />
                </a>
                <a href="https://totem.totemapp.com/">
                  <img src={`${logoInverted}`} />
                </a>
              </Grid.Row>
            </ClientImages>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </PressSegment>

    <FooterSegment vertical>
      <Grid container vertical>
        <FooterTop>
          <Grid.Column computer={6} tablet={6} mobile={16} floated="left">
            <img className="footer-logo" src={`${logoIcon}`} />
          </Grid.Column>
          <FooterIcons tablet={6} computer={6} mobile={16} floated="right" textAlign="right">
            <a href="https://medium.com/building-the-best-press-page">
              <img src={`${iconMedium}`} />
            </a>
            <a href="https://twitter.com/PressTotem">
              <img src={`${iconTwitter}`} />
            </a>
            <a href="https://www.facebook.com/Totemapp">
              <img src={`${iconFb}`} />
            </a>
          </FooterIcons>
        </FooterTop>
        <Grid.Row style={{ padding: '0' }}>
          <Grid.Column computer={14} tablet={14} mobile={16} floated="left">
            <FooterLinks>
              <a href="https://medium.com/building-the-best-press-page/about-c2a52fb68220#.x1ouegcjq">About</a>
              <a href="https://medium.com/building-the-best-press-page">Blog</a>
              <a href="mailto:concierge@totemapp.com">Support</a>
              <a href="https://medium.com/building-the-best-press-page/totem-privacy-policy-7dedf1a71ccf#.8y4afk13m">
                Privacy
              </a>
              <a href="https://medium.com/building-the-best-press-page/totem-terms-of-service-e72959032090#.rf25huw2j">
                Terms of Service
              </a>
              <a href="https://totem.totemapp.com/company">Press</a>
            </FooterLinks>
            <FooterCopyright as="h5">&copy; 2020 Totem, Inc. All Rights Reserved.</FooterCopyright>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={2}
            computer={2}
            floated="right"
            textAlign="right"
            verticalAlign="bottom"
            className="jdi-wrap">
            <a href="http://www.jones-dilworth.com/">
              <img src={`${logoJDI}`} />
            </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FooterSegment>
  </ResponsiveContainer>
);

export default HomepageLayout;
