import { hot } from 'react-hot-loader/root';
import 'theme/semantic.less';

import { Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';

import Homepage from './screens/Homepage';

const App = () => (
  <Switch>
    <Route exact path="/" component={Homepage} />
  </Switch>
);

export default hot(App);
