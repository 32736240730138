const {
  API_URL,
  APP_NAME,
  SENTRY_DSN,
} = window.__ENV__;

export {
  API_URL,
  APP_NAME,
  SENTRY_DSN,
};
